<template>
  <div>
    <div><h1>Admin</h1></div>
    <b-table-lite
      :items="items"
      style="margin-top: 64px"
    />
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTableLite } from 'bootstrap-vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCardCode,
    BTableLite,
  },
  data() {
    return {
      items: [
        {
          age: 40, first_name: 'Dickerson', last_name: 'Macdonald', Occupation: 'Job',
        },
        {
          age: 21, first_name: 'Larsen', last_name: 'Shaw', Occupation: 'Job',
        },
        {
          age: 89, first_name: 'Geneva', last_name: 'Wilson', Occupation: 'Business',
        },
        {
          age: 38, first_name: 'Jami', last_name: 'Carney', Occupation: 'Business',
        },
        {
          age: 40, first_name: 'James', last_name: 'Thomson', Occupation: 'Job',
        },
      ],
    }
  },
}
</script>

<style>

</style>
